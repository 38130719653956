

.fa-smile:hover + .chat-react-container, .chat-react-container:hover{
  display: flex !important; 
  opacity: 1;
}


.your-message .chat-react-container{
  border-radius: 25px;
  background-color: var(--secondary-dark);
  gap: 10px;
  position:absolute;
  right:-50px;
  bottom:25px;
  box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -webkit-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -moz-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  opacity: 0;
  display: none !important;
  z-index: 9;
}

.you .chat-react-container{
  border-radius: 25px;
  background-color: var(--secondary-dark);
  gap: 10px;
  position:absolute;
  right:-50px;
  bottom:20px;
  box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -webkit-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -moz-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  opacity: 0;
  display: none;
  z-index: 9;
}

.them .chat-react-container{
  border-radius: 25px;
  background-color: var(--secondary-dark);
  gap: 10px;
  position:absolute;
  left:-75px;
  bottom:20px;
  box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -webkit-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  -moz-box-shadow: -3px 7px 20px 1px rgba(0,0,0,0.25) !important;
  opacity: 0;
  display: none;
  z-index: 9;
}

.chat-action-container{
  position: relative;
}

.your-message, .chat-message{
  position: relative;
}

.your-message .reaction-count{
  position: absolute;
  bottom:-15px;
  left:0px;
  padding: 0 7.5px 0 5px;
  text-align: center;
  background-color:var(--secondary-dark);
  border-radius: 15px;
  width: fit-content;
  font-size: 14px;
  box-shadow: 0 0 3px 2px rgba(0,0,0,0.15);
}

.your-message .reaction-count:hover, .chat-message .reaction-count:hover{
  text-decoration: none;
}

.chat-message .reaction-count{
  position: absolute;
  bottom:-15px;
  right:0px;
  padding: 0 7.5px 0 5px;
  text-align: center;
  background-color:var(--secondary-dark);
  border-radius: 15px;
  width: fit-content;
  font-size: 14px;
  box-shadow: 0 0 3px 2px rgba(0,0,0,0.15);
}

.mobile-video-container iframe{
  border-radius: 15px !important; 
}



.notification-container{
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  margin: 0 15px;
  color:var(--primary);
}

.notification-container .message .text{
  font-size: 14px;
}

.notification-container .image{
  position: relative;
}

.notification-container .image .sub-icon{
  color:white;
  background-color: var(--primary);
  font-size: 12px;
  padding:5px 7.5px;
  margin-bottom: 0;
  border-radius: 50rem;
  position: absolute;
  display: inline;
  top:-5px;
  right: 0;
  box-shadow:0 0 2px 2px rgba(0,0,0,0.1);
}

.notification-container .message .text.read{
  opacity: 0.6;
}

.notification-container .message .timestamp{
  font-size: 12px;
  color:var(--primary);
  font-weight: bold;
}

.notification-container .message .timestamp.text-muted{
  font-weight: normal;
}

.notification-container .fa.fa-circle{
  color: var(--primary);
  font-size: 12px;
  position: absolute;
  right:25px;
}


.notification-container:hover{
  background-color:rgba(0,0,0,0.15);
}